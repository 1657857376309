import React, { useEffect, useState } from 'react'
import styles from './style.module.scss'
import TagInput from '../../../layout/tag-input/tag'
import customAxios from '../../../axios/custom'
import { useDispatch } from 'react-redux'
import { set_get_order } from '../../../redux/modal'
import { GetOrderDetails } from '../../../layout/modals/modals'
import { useTabletOrMobile } from '../../../utils/useQueryMedia'
import moment from 'moment'
import { pdf } from '@react-pdf/renderer';
import InvoicePDF from './InvoicePdf';

const Order = ({ title, subtitl, accountDetails }) => {
	const [orders, setOrders] = useState([])
	const [totalPages, setTotalPages] = useState(1)
	const [currentPage, setCurrentPage] = useState(1)
	const [pageSize, setPageSize] = useState(5)
	const dispatch = useDispatch()
	useEffect(() => {
		fetchOrders()
	}, [currentPage, pageSize])

	const fetchOrders = async () => {
		try {
			const response = await customAxios.get(
				`/api/v1/order?page=${currentPage}&pageSize=${pageSize}`
			)
			setOrders(response.data.orders)
			setTotalPages(response.data.totalPages)
		} catch (error) {
			console.error('Error fetching orders:', error)
		}
	}
	const [idOrder, setIdOrder] = useState()
	const handlePageChange = (page) => {
		setCurrentPage(page)
	}

	const handleFnOrder = (id) => {
		console.log(id)
		dispatch(set_get_order())
		setIdOrder(id)
	}

	const openPDF = async (order) => {
		const blob = await pdf(<InvoicePDF order={order} accountDetails={accountDetails} />).toBlob();
		const url = URL.createObjectURL(blob);
		window.open(url, '_blank');
	};


	const isMobile = useTabletOrMobile()
	return (
		<div className={styles.order_box}>
			<TagInput
				title={title}
				subTile={subtitl}
				text_btn={'Ajouter une boutique'}
				isOption={true}
			/>
			<div className={styles.box_array}>
				<div className={styles.array}>
					{isMobile ? (
						<div className={styles.title}>
							<p className={styles.title_five}>Shop</p>
							<p className={styles.title_six}>Détails</p>
							<p className={styles.title_six}>Facture</p>
						</div>
					) : (
						<div className={styles.title}>
							<p className={styles.title_one}>Nom client</p>
							<p className={styles.title_two}>Prix</p>
							<p className={styles.title_three}>Email client</p>
							<p className={styles.title_four}>Date</p>
							<p className={styles.title_five}>Shop</p>
							<p className={styles.title_six}>Détails</p>
							<p className={styles.title_six}>Facture</p>
						</div>
					)}

					{orders.length > 0 &&
						orders.map((produit, index) => {
							return (
								<React.Fragment key={produit._id}>
									{isMobile ? (
										<div className={styles.desc}>
											<p className={styles.desc_five}>
												{produit.shop}
											</p>
											<p
												className={styles.desc_six}
												onClick={(e) =>
													handleFnOrder(produit._id)
												}
											>
												Plus de détails
											</p>
											<p
												className={styles.desc_six}
												onClick={_ => openPDF(produit)}
											>
												Afficher la facture
											</p>
										</div>
									) : (
										<div
											className={styles.desc}
											key={index}
										>
											<div className={styles.img_flex}>
												<p className={styles.desc_one}>
													{produit.name}
												</p>
											</div>
											<p className={styles.desc_two}>
												{new Intl.NumberFormat(
													'en-US',
													{ minimumFractionDigits: 2 }
												).format(produit?.price)}
												€
											</p>
											<p className={styles.desc_three}>
												{produit.customer_email}
											</p>
											<p className={styles.desc_four}>
												{moment(
													new Date(produit.dateOffre)
												).format('DD-MM-yyyy HH:mm')}
											</p>
											<p className={styles.desc_five}>
												{produit.shop_name}
											</p>
											<p
												className={styles.desc_six}
												onClick={(e) =>
													handleFnOrder(produit._id)
												}
											>
												Plus de détails
											</p>
											<p
												className={styles.desc_six}
												onClick={_ => openPDF(produit)}
											>
												Afficher la facture
											</p>
										</div>
									)}
								</React.Fragment>
							)
						})}
				</div>
				{idOrder && <GetOrderDetails id={idOrder}></GetOrderDetails>}
				<div className={styles.paginator}>
					<button
						disabled={currentPage === 1}
						onClick={() => handlePageChange(currentPage - 1)}
					>
						<i className='fa-solid fa-backward'></i>
					</button>
					<span>
						Page {currentPage} sur {totalPages}
					</span>
					<button
						disabled={currentPage === totalPages}
						onClick={() => handlePageChange(currentPage + 1)}
					>
						<i className='fa-solid fa-forward'></i>
					</button>
				</div>
			</div>
		</div>
	)
}

export default Order
