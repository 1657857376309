import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import Logo from '../../../assets/images/logo.png';

const InvoicePDF = ({ order, accountDetails }) => (
    <Document>
        <Page size="A4" style={styles.page}>
            {/* Header */}
            <View style={styles.header}>
                <View>
                    <Image src={Logo} style={styles.logo} />
                    <Text style={[styles.text, styles.bold]}>Albert EXTRA</Text>
                    <Text style={styles.text}>Proposé par</Text>
                    <Text style={styles.text}>Albert VENTURES LLP</Text>
                    <Text style={styles.text}>71 Shelton Street, London UK</Text>
                </View>
                <View style={{ marginTop: 70 }}>
                    <Text style={[styles.text, styles.bold]}>{accountDetails?.individual?.first_name ? `${accountDetails?.individual?.first_name} ${accountDetails?.individual?.last_name}` : accountDetails?.company?.name}</Text>
                    <Text style={styles.text}>{accountDetails?.individual?.email}</Text>
                    <Text style={styles.text}>{accountDetails?.individual?.address?.line1 || accountDetails?.company?.address?.line1}</Text>
                    {(accountDetails?.individual?.address?.line2 || accountDetails?.company?.address?.line2) && <Text style={styles.text}>{accountDetails?.individual?.address?.line2 || accountDetails?.company?.address?.line2}</Text>}
                    <Text style={styles.text}>{accountDetails?.individual?.address?.city || accountDetails?.company?.address?.city}, {accountDetails?.individual?.address?.postal_code || accountDetails?.company?.address?.postal_code}</Text>
                </View>
            </View>

            {/* Order Details */}
            <View style={styles.section}>
                <Text style={[styles.text, styles.bold]}>Détails de la commande</Text>
                <Text style={styles.text}>Boutique: {order?.shop_name}</Text>
                <Text style={styles.text}>Date: {new Date(order?.dateOffre).toLocaleDateString()}</Text>
                {/* <Text style={styles.text}>Statut: {order?.status.toUpperCase()}</Text> */}
            </View>

            {/* Product Table */}
            <View style={styles.section}>
                <Text style={[styles.text, styles.bold, { marginBottom: 5 }]}>Produits</Text>
                <View style={styles.table}>
                    {/* Table Header */}
                    <View style={[styles.tableRow, { backgroundColor: '#f0f0f0' }]}>
                        {/* <Text style={styles.tableHeader}>Image</Text> */}
                        <Text style={styles.tableHeader}>Titre</Text>
                        <Text style={styles.tableHeader}>Quantité</Text>
                        <Text style={styles.tableHeader}>Prix (€)</Text>
                    </View>

                    {/* Table Rows */}
                    {order?.products.map((product, ind, arr) => (
                        <View key={product._id} style={arr.length - 1 === ind ? { flexDirection: 'row' } : styles.tableRow}>
                            {/* <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <Image src={{ uri: product.photos[0] }} style={styles.image} />
              </View> */}
                            <Text style={styles.tableCell}>{product.title}</Text>
                            <Text style={styles.tableCell}>x{product.quantity}</Text>
                            <Text style={styles.tableCell}>{product.price.toFixed(2)}</Text>
                        </View>
                    ))}
                </View>
                {/* Total Price */}
                <View style={[styles.tableRow, { borderLeft: '1px solid #000', borderRight: '1px solid #000', paddingTop: 10 }]}>
                    <Text style={[styles.tableCell, styles.bold]}>Total</Text>
                    <Text style={[styles.tableCell, styles.bold]}>{order?.products?.reduce((sum, product) => sum + product?.quantity, 0)} {order?.products?.reduce((sum, product) => sum + product?.quantity, 0) > 1 ? 'Articles' : 'Article'}</Text>
                    <Text style={[styles.tableCell, styles.bold]}>€{order?.price.toFixed(2)}</Text>
                </View>
            </View>


            {/* <View style={styles.footer}>
                <Text style={[styles.text, styles.bold]}>Total: €{order?.price.toFixed(2)}</Text>
            </View> */}
        </Page>
    </Document>
);

const styles = StyleSheet.create({
    page: {
        padding: 30,
        fontSize: 12
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 20
    },
    logo: {
        width: 130,
        height: 40,
        marginBottom: 30
    },
    text: {
        fontSize: 12
    },
    bold: {
        fontFamily: 'Helvetica-Bold'
    },
    section: {
        marginBottom: 20
    },
    table: {
        width: '100%',
        border: '1px solid #000'
    },
    tableRow: {
        flexDirection: 'row',
        borderBottom: '1px solid #000'
    },
    tableHeader: {
        fontFamily: 'Helvetica-Bold',
        padding: 5,
        flex: 1,
        textAlign: 'center'
    },
    tableCell: {
        padding: 5,
        flex: 1,
        textAlign: 'center'
    },
    image: {
        width: 40,
        height: 40,
        marginRight: 5,
        borderRadius: 5
    },
    footer: {
        marginTop: 20,
        textAlign: 'right'
    },
});

export default InvoicePDF;