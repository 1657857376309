import axios from "axios";
import customAxios from "../axios/custom";

export async function Create({ from, data }) {
  console.log(data);
  const res = await customAxios.post("/api/v1/" + from, data);
  return res.data;
}

export async function GetId(from, id) {
  const res = await customAxios.get("/api/v1/" + from + "/" + id);
  return res.data;
}

export async function Get(from) {
  const res = await customAxios.get("/api/v1/" + from);
  return res.data;
}

export async function Update({ from, id, data }) {
  const res = await customAxios.put("/api/v1/" + from + "/" + id, data);
  return res.data;
}

export async function Delete({ from, data }) {
  const res = await customAxios.delete("/api/v1/" + from + "/" + data);
  return res.data;
}


export async function Token(data) {
  const res = await customAxios.get("/api/v1/shop/token/" + data);
  return res.data;
}

export async function StatusChange(data) {
  console.log(data);
  const res = await customAxios.put("/api/v1/order/status/" + data);
  return res.data;
}

export async function GetOrdersMonth(data) {
  console.log(data);
  const res = await customAxios.get("/api/v1/order/getOrders");
  return res.data;
}

export async function GetTopProd(data) {
  console.log(data);
  const res = await customAxios.get("/api/v1/product/get-top");
  return res.data;
}

export async function getStatusChange(data) {
  console.log(data);
  const res = await customAxios.post("/api/v1/auth/login", data);

  return res.data;
}

export async function userLogin(data) {

  const res = await customAxios.get("/api/v1/auth/user/" + data.token);

  return res.data;
}
export async function updateUser(data) {
  console.log(data);
  const res = await customAxios.put("/api/v1/auth/updateUser", data);

  return res.data;
}

export async function generatePaymentIntent(data) {
  console.log(data);
  const res = await customAxios.post("/api/v1/payment/generate-payment-intent");

  return res.data;
}

export async function checkVerificationStatus(data) {
  const res = await customAxios.post("/api/v1/payment/check-verification-status", data);

  return res.data;
}

export async function fetchTransactions(accountId) {
  const res = await customAxios.get(`/api/v1/payment/balance-transactions/${accountId}`);

  return res.data;
}

export async function getConnectAccountDetails(accountId) {
  const res = await customAxios.get(`/api/v1/payment/account-details/${accountId}`);

  return res.data;
}

export async function getConnectAccountCompleteDetails(accountId) {
  const res = await customAxios.get(`/api/v1/payment/complete-account-details/${accountId}`);

  return res.data;
}